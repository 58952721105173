<template>
  <div class="body" v-loading="Loading">
    <el-tag
      class="organization-tag"
      v-if="MemberType === 'formal' && this.joinOrganizations.length"
      closable
      @close="CloseOrgTag"
      ><span class="el-icon-success icon-success"></span>已加入组织：{{ this.joinOrganizations.join("、") }}
    </el-tag>
    <div class="position-list">
      <p class="title">
        {{ MemberType === "register" ? "申请信息" : "会员信息" }}
      </p>
      <PositionList :type="MemberType" :UserId="user_id" :ID="id" :updateList="refreashList" :loading.sync="Loading" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PositionList from "../../components/Member/PositionList.vue";
import { MemberMessage } from "../../api/member/member-detail";
export default {
  props: {
    data: {
      type: String,
    },
  },
  components: { PositionList },
  data() {
    return {
      joinOrganizations: [],
      Loading: false,
      saveLoading: false,
      id: this.data ? this.$deCode(this.data).id : this.$route.params.user_id,
      user_id: 0,
      MemberType: this.data ? this.$deCode(this.data).type : this.$route.params.tab,
    };
  },
  computed: {
    positionList() {
      return this.$store.getters.positionList;
    },
  },
  created() {
    this.refreashList();
  },
  methods: {
    // 获取会员信息
    getMemberMessage(requestData) {
      this.Loading = true;
      MemberMessage(requestData)
        .then((res) => {
          const { data } = res;
          let organizationList = [];
          this.user_id = data.user_id;
          data.data.forEach((element) => {
            organizationList.push(element.join_organization_name);
          });
          this.joinOrganizations = [...new Set(organizationList)];
          this.$store.commit("setPositionList", data.data);
          this.Loading = false;
        })
        .catch((err) => {
          this.Loading = false;
        });
    },
    // 关闭加入组织提示
    CloseOrgTag() {
      this.joinOrganizations = [];
    },
    //刷新列表
    refreashList() {
      const data = {
        id: this.id,
        tab: this.MemberType,
      };
      this.getMemberMessage(data);
    },
  },
  //调整title
  beforeRouteEnter(to, from, next) {
    if (!to.params.data) return next();
    const type = JSON.parse(decodeURIComponent(to.params.data)).type;
    switch (type) {
      case "register":
        to.meta.title = "申请信息";
        break;
      default:
        to.meta.title = "会员信息";
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding: 0;
  .organization-tag {
    margin: 15px 12px 10px;
    width: calc(100% - 24px);
    display: flex;
    align-items: center;
    ::v-deep.el-icon-close:before {
      line-height: 16px;
    }
    ::v-deep.el-icon-close {
      margin-left: auto;
      top: 0;
    }
    .icon-success {
      font-size: 16px;
      margin-right: 6px;
    }
  }

  .title {
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 12px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 20px;
  }
}
</style>
